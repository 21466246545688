import {
  HashRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import LandingPage from "./LandingPage";
import RedirectPage from "./redirectToApp";

// Create a wrapper component to handle route changes
function RouteTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {
    ReactGA.initialize("G-8ZKV4XQHGC"); // Replace with your Measurement ID
  }, []);

  return (
    <Router>
      <RouteTracker /> {/* Add this inside the Router */}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/redirect" element={<RedirectPage />} />
      </Routes>
    </Router>
  );
}

export default App;
