import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://ngovhqybxeiwyezwpjgi.supabase.co/";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5nb3ZocXlieGVpd3llendwamdpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTcyNzcwNTcsImV4cCI6MjAzMjg1MzA1N30.qbxiDAd2l5YWMAYAYxnY7QHriJXj1sSS0rBJk5XFz5c";

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
});
