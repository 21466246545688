import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RedirectPage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const deepLink = urlParams.get('deepLink');
    console.log(deepLink)

    if (deepLink) {
      window.location.href = deepLink;
    } else {
      // Handle error or redirect to a default page
      window.location.href = 'https://explorifyapp.com';
    }
  }, [location]);

  return (
    <div>
      <p>Redirecting to app...</p>
    </div>
  );
};

export default RedirectPage;