import { useEffect, useState } from "react";
import { supabase } from "../lib/supabase";

const UserCounter = () => {
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    async function fetchUserCount() {
      try {
        const { data: individualData, error } = await supabase
          .from("individual")
          .select("full_name");

        if (error) {
          throw error;
        }
        setUserCount(individualData.length);
      } catch (error) {
        console.error("Error fetching user count:", error);
      }
    }

    fetchUserCount();
  }, []);

  return (
    <div>
      <span>Number of authenticated users: {userCount}</span>
    </div>
  );
};

export default UserCounter;
