import React, { useRef, useEffect, useState, RefObject } from "react";

const PhoneFrame: React.FC = () => {
  const phoneRef: RefObject<HTMLDivElement> = useRef(null);
  const [isFullyVisible, setIsFullyVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    const phoneElement = phoneRef.current;
    let observer: IntersectionObserver;

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      setIsFullyVisible(entry.isIntersecting && entry.intersectionRatio === 1);
    };

    observer = new IntersectionObserver(handleIntersect, {
      threshold: 1.0, // This means 100% of the target is visible
      rootMargin: "0px" // Adjust this if you need some buffer
    });

    if (phoneElement) {
      observer.observe(phoneElement);
    }

    return () => {
      if (phoneElement) {
        observer.unobserve(phoneElement);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = (e: WheelEvent) => {
      if (isFullyVisible && phoneRef.current) {
        const phoneElement = phoneRef.current;
        const maxScroll = phoneElement.scrollHeight - phoneElement.clientHeight;
        
        if (scrollPosition >= maxScroll && e.deltaY > 0) {
          // At the bottom and scrolling down, allow main page to scroll
          setIsAtEnd(true);
          return;
        }
        
        if (scrollPosition <= 0 && e.deltaY < 0) {
          // At the top and scrolling up, allow main page to scroll
          setIsAtEnd(true);
          return;
        }
        
        // Otherwise, scroll the phone frame
        e.preventDefault();
        setIsAtEnd(false);
        const delta = e.deltaY;
        let newScrollPosition = scrollPosition + delta;
        newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScroll));

        setScrollPosition(newScrollPosition);
        phoneElement.scrollTop = newScrollPosition;
      }
    };

    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [isFullyVisible, scrollPosition, isAtEnd]);

  return (
    <div>
      <div 
        ref={phoneRef} 
        className="w-[300px] h-[600px] overflow-hidden border-[10px] border-black rounded-[30px] bg-white mt-[1000px]"
      >
        <div className="w-full h-[1200px]">
          <img 
            src="/assets/homepage/homepage.svg" 
            alt="Homepage content" 
            className="w-full h-auto"
          />
        </div>
        <div className="absolute z-10">
          <img 
            src="/assets/homepage/navbar.svg" 
            alt="Navigation bar" 
            className="w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneFrame;