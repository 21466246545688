// Imports
import "./index.css";
import { Link } from "react-router-dom";

// Components
import PhoneFrame from "./components/phoneFrame";
import UserCounter from "./components/userCounter";

function LandingPage() {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col text-5xl mt-[100px]">explorify</div>
        <div>
          <UserCounter />
        </div>
        <PhoneFrame />
      </div>

      <div className="mt-[2000px]">scrollable content</div>
      <Link to="/redirect" className="flex justify-center mt-[100px]"> Test </Link>
    </div>
  )
};

export default LandingPage